.modal {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    border: none;
    height: auto;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 9;
}