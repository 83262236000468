.about {
    display: flex;
    justify-content: space-between;

    &--mobile {

        &__image-container {
            object-fit: contain;
            padding-bottom: 20px;
        }
    
        &__image {
            width: 100%;
            border-radius: 5px;
        }
    
        &__text {
            display: flex;
            flex-direction: column;
        }
    
        &__quote {
            font-weight: bold;
            font-size: 20px;
            padding-bottom: 20px;
        }
    
        &__description {
            font-size: 14px;
        }
    }

    &__image-container {
        width: 40%;
        object-fit: contain;
    }

    &__image {
        width: 100%;
        border-radius: 5px;
    }

    &__text {
        width: 55%;
        display: flex;
        flex-direction: column;
    }

    &__quote {
        text-align: right;
        font-weight: bold;
        font-size: 2vw;
    }

    &__description {
        text-align: right;
        font-size: 14px;
    }
}

.contact {
    padding: 30px 0;
    display: flex;
    flex-direction: column;

    &__icons {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 20px;
    }

    &__icon {
        color: #b8490b;

        &:not(:last-child) {
            padding-right: 20px;
        }
    }

    &__email {
        margin: auto;
    }
}