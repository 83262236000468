.tab {
    font-size: 14px;
    text-transform: uppercase;

    &--mobile {

    }

    &--active {
        font-weight: bold;
    }
}