.book-detail-page {

    display: flex;
    justify-content: space-between;
    padding-bottom: 30px;

    &--mobile {
       
        padding-bottom: 30px;

        &__description {
            padding-bottom: 20px;
        }

        &__image {
            width: 100%;
            padding-bottom: 20px;
        }

        &__title {
            text-transform: uppercase;
            font-weight: bold;
            padding-bottom: 10px;
        }

        &__about {
            font-size: 13px;
        }
    }

    &__images,
    &__description {
        width: 45%;
    }

    &__sticky {
        position: sticky;
        top: 0;
    }

    &__image {
        width: 100%
    }

    &__title {
        text-transform: uppercase;
        font-weight: bold;
        padding-bottom: 10px;
    }

    &__about {
        font-size: 13px;
    }
}