.book {
    height: 100%;
    padding-bottom: 30px;

    &--mobile {
        padding-bottom: 50px;
    }

    &__image {
        width: 100%;
        height: 85%;
        object-fit: contain;
        padding-bottom: 20px;

        &--mobile {
            width: 100%;
            object-fit: contain;
            padding-bottom: 20px;
        }
    }

    &__title {
        text-transform: uppercase;
        font-weight: bold;
        padding-bottom: 10px;
    }

    &__about {
        font-size: 13px;
        text-align: justify;
    }
}