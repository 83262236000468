.main {
    width: 65%;
    height: calc(100vh - 230px);
    overflow: auto;
    margin: 200px auto auto;
    padding-top: 30px;

    &::-webkit-scrollbar {
        display: none;
    }
}