.title {
    width: 100%;
    height: 150px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    position: relative;

    &__illustration, 
    &__text {
        max-height: 80%;
    }
}