.dropdown-menu {

    &__trigger {
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        height: 50px;
        width: 100%;
        background-color:  #b8490b;
        z-index: 3;
    }

    &__label {
        text-transform: uppercase;
        font-weight: bold;
        color: white;
    }

    &__options {
        display: flex;
        flex-direction: column;
        position: fixed;
        align-items: center;
        width: 100%;
        background-color: white;

        transform: translateY(-100vh);
        transition: transform 1s;

        &--open {
            transform: translateY(0);
        }
    }

    &__option {
        padding: 20px 0;

        &--active {
            font-weight: bold;
        }
    }

    &__divider {
        width: 100%;
        height: 1px;
        background-color: rgba(184, 73, 11, 0.5);
    }
}