.menu {
    position: relative;
    width: 65%;
    background-color: white;
    margin: auto;
   
    &__list {
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-inline-start: 0;
        margin-block-start: 0;
        margin-block-end: 0;
        height: 50px;

    }
}