.grid {

    &--2 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: 1fr;
        column-gap: 10%;
        row-gap: 5%;
    }

    &--3 {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: 30%;
        column-gap: 5%;
        row-gap: 5%;
    }
}