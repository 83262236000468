.gallery { 

    &__image-container,
    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__image {
        &:hover {
            cursor: pointer;
            transform: scale(1.2);
        }
    }

    &__modal-content {
        max-width: 80vw;
        max-height: 80vh;
        object-fit: scale-down;
    }

    &--mobile {

        &__image-container,
        &__image {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        
        &__image-container {
            margin-bottom: 50px;
        }
    }
    
}

